import Carousel from "react-bootstrap/Carousel";
import v1 from "../image/1.jpg";
import v2 from "../image/2.jpg";
import v3 from "../image/3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import "./css/Home.css";
import Footer from "./Footer";
import device from "../image/Device.svg";
import scbx from "../image/SCBX.webp";
import cpf from "../image/CPF.jpg";
import pttgc from "../image/PTTGC.png";
import scc from "../image/SCC.png";
import device2 from "../image/Device2.png";

export default function Home() {
  const isMobile = window.innerWidth <= 640;
  const setstyle = isMobile ? { height: "60vh" } : { height: "80vh" };
  return (
    <>
      <div className="Home-box">
        <Header />
        <Carousel>
          <Carousel.Item interval={1000}>
            <img
              className="d-block w-100"
              style={setstyle}
              src={v1}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3> Stock Prediction </h3> <p> Fundamental Data </p>{" "}
            </Carousel.Caption>{" "}
          </Carousel.Item>{" "}
          <Carousel.Item interval={500}>
            <img
              className="d-block w-100"
              style={setstyle}
              src={v2}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3> Stock Prediction </h3> <p> Technical Data </p>{" "}
            </Carousel.Caption>{" "}
          </Carousel.Item>{" "}
          <Carousel.Item>
            <img
              className="d-block w-100"
              style={setstyle}
              src={v3}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3> Stock Prediction </h3> <p>News-Online Data </p>{" "}
            </Carousel.Caption>{" "}
          </Carousel.Item>{" "}
        </Carousel>{" "}
      </div>{" "}
      <div className="Intro">
        <p>
          <h4> #Investing can be simple </h4> Welcome to our innovative project
          focused on optimizing stock price forecasting for companies listed on
          the SET50 index. In today 's dynamic financial landscape, staying
          ahead of market trends is crucial. Our endeavor combines cutting -
          edge Deep Learning techniques with a diverse range of data sources,
          including news-online data, fundamental, and technical data, to
          provide accurate and insightful stock price predictions.{" "}
        </p>{" "}
        <img src={device} alt="device" />
      </div>{" "}
      <div className="liststock">
        <p> Example Stocks </p>{" "}
        <div className="Examplestock">
          <img src={scbx} alt="scbx" />
          <img src={cpf} alt="cpf" />
          <img src={scc} alt="scc" />
          <img src={pttgc} alt="pttgc" />
        </div>
      </div>
      <div className="Intro-2">
        <img src={device2} alt="Macbook-air" />
        <p>
          <h4> # Stock News Access for Everyone </h4> Our advanced system merges
          news updates, fundamental data, and technical analysis, delivering a
          comprehensive and user-friendly stock performance overview. It caters
          to a wide range of investors and traders, accommodating diverse
          investment horizons and strategies.{" "}
        </p>{" "}
      </div>{" "}
      <Footer />
    </>
  );
}
