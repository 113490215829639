import React, { useState, useEffect } from "react";
import "./css/Stock_Page.css";
import Header from "./Header";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import axios from "axios";
import stocks_page_nav from "../image/stocks_page_nav.jpg";
import Graph from "./Graph";
import NewsCard from "./News-card";
import { FcComboChart } from "react-icons/fc";

export default function StockPage() {
  const { firstPart, date } = useParams();
  const [stockNames, setStockNames] = useState([]);

  useEffect(() => {
    axios
      // .get(`http://localhost:3001/Stockname/${firstPart}`)
      .get(process.env.REACT_APP_API_DATABASE + `/Stockname/${firstPart}`)
      .then((response) => {
        setStockNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stock names:", error);
      });
  }, [firstPart]);

  const Industry = stockNames.length > 0 ? stockNames[0].StockIndustry : "";

  return (
    <>
      <Header />
      <div className="box">
        <img
          src={stocks_page_nav}
          alt="Stocks_page_nav"
          className="stocks_page_nav"
        />
      </div>{" "}
      {
        <div className="box-data">
          <h1> {firstPart.toUpperCase()} </h1> <h3> Date: {date} </h3>
        </div>
      }
      <Graph />
      <div className="shap-box">
        <img
          src={"/shap-value/" + firstPart + "/" + date + ".png"}
          alt="shap"
        />
        <p>
          <h6>
            {" "}
            <FcComboChart /> Shap Value{" "}
          </h6>{" "}
          <hr className="gradient-line" /> This chart provides a snapshot of
          what factors a deep-learning model considers when predicting the
          closing price of a stock for the next week. It ranks these factors in
          order of their impact: the higher up on the chart, the more influence
          that factor has on the prediction.
        </p>
      </div>
      <NewsCard myIndustryprop={Industry} />
      <div className="card" style={{ padding: "40px 30px" }}>
        <div
          className="card-header"
          style={{ fontSize: "23px", backgroundColor: "#444", color: "white" }}
        >
          Business{" "}
        </div>{" "}
        <div className="card-body">
          <blockquote className="blockquote mb-0" style={{ fontSize: "15px" }}>
            {" "}
            {stockNames.map((stockName, index) => (
              <p key={index} value={stockName}>
                {" "}
                {stockName.StockDescription}{" "}
              </p>
            ))}{" "}
          </blockquote>{" "}
        </div>{" "}
      </div>{" "}
      <Footer />
    </>
  );
}
