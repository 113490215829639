import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { AiOutlineStock } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import "./css/Search_Stock.css";
import video from "../image/2.mp4";
import ImageSearch from "../image/ImageSearch.jpg";
import axios from "axios";

export default function SearchStock() {
  const [selectedStock, setSelectedStock] = useState("");
  const [stockNames, setStockNames] = useState([]);
  const [selectedStockDate, setSelectedStockDate] = useState("");
  const [StartPredictDate, setStartPredictDate] = useState([]);
  const isMobile = window.innerWidth <= 640;
  const navigate = useNavigate();

  const handleStockSelection = (e) => {
    const selectedValue = e.target.value;
    setSelectedStock(selectedValue);
  };

  const handleStartPredictDate = (e) => {
    const selectedDate = e.target.value;
    setSelectedStockDate(selectedDate);
  };

  function CheckForm(e) {
    e.preventDefault();
    if (!selectedStock || !selectedStockDate) {
      alert("Please select both a stock name and a start date.");
    } else {
      navigate(`/STOCK/${selectedStock}/${selectedStockDate}`);
    }
  }

  useEffect(() => {
    axios
      //  .get("http://localhost:3001/selectedStock")
      .get(process.env.REACT_APP_API_DATABASE + "/selectedStock")
      .then((response) => {
        setStockNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stock names:", error);
      });
  }, []);

  useEffect(() => {
    axios
      // .get("http://localhost:3001/Start_Predict_Date")
      .get(process.env.REACT_APP_API_DATABASE + "/Start_Predict_Date")
      .then((response) => {
        setStartPredictDate(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stock start predict date:", error);
      });
  }, []);

  const adjustToUserTimezone = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - userTimezoneOffset);
    return date.toISOString().split("T")[0];
  };

  return (
    <>
      <div className="box-search">
        <Header />
        {isMobile ? (
          <img src={ImageSearch} alt="ImageSearch" />
        ) : (
          <video src={video} autoPlay muted loop />
        )}
        <Form onSubmit={CheckForm}>
          <Form.Select
            className="form-select-1"
            onChange={handleStockSelection}
            value={selectedStock}
          >
            <option> Pick the company's stock name</option>{" "}
            {Array.from(
              new Set(stockNames.map((stockName) => stockName.StockIndustry))
            ).map((industry, index) => (
              <optgroup label={industry} key={index}>
                {stockNames.map((stockName, innerIndex) => {
                  if (stockName.StockIndustry === industry) {
                    return (
                      <option value={stockName.StockName} key={innerIndex}>
                        {stockName.StockName} - {stockName.StockFullName}
                      </option>
                    );
                  }
                  return null;
                })}
              </optgroup>
            ))}
          </Form.Select>
          {/* Date Picker for Start Date */}
          <div className="pick-date">
            <Form.Select
              className="form-select-2"
              onChange={handleStartPredictDate}
              value={selectedStockDate}
            >
              <option> Pick a demo date</option>
              {StartPredictDate.map((date, innerIndex) => (
                <option
                  value={adjustToUserTimezone(date.Predict_Date)}
                  key={innerIndex}
                >
                  {adjustToUserTimezone(date.Predict_Date)}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="BottonForecast">
            <Button type="submit" variant="dark" style={{ fontSize: "18px" }}>
              {" "}
              Forecast <AiOutlineStock />
            </Button>{" "}
          </div>
        </Form>
      </div>
    </>
  );
}
