import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import IntroHome from "./component/Intro_Home";
import SearchStock from "./component/Search_Stock";
import Stock from "./component/Stock_Page";
import News from "./component/News";
import NotFound from "./component/NotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IntroHome />} />
          <Route path="/HOME" element={<Home />} />
          <Route path="/SEARCHSTOCKS" element={<SearchStock />} />
          <Route path="/STOCK/:firstPart/:date" element={<Stock />} />
          <Route path="/News" element={<News />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
