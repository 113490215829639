import React, { useState, useEffect } from "react";
import "./css/Stock_Page.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Chart } from "react-google-charts";

export default function Graph() {
  const { firstPart, date } = useParams();
  const [stockPredict, setStockPredict] = useState([]);
  const isMobile = window.innerWidth <= 640;

  useEffect(() => {
    axios
      //  .get(`http://localhost:3001/selectedStock/${firstPart}/${date}`)
      .get(
        process.env.REACT_APP_API_DATABASE +
          `/selectedStock/${firstPart}/${date}`
      )
      .then((response) => {
        setStockPredict(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stock prediction:", error);
      });
  }, [firstPart, date]);

  const ComboChart = [
    ["Date", "Real Price", "Open", "Real-Closed", "High", "Predict Price"],
    ...stockPredict.map((stock) => {
      const date = new Date(stock.Real_Date);
      // const timeZoneOffset = date.getTimezoneOffset();
      // date.setMinutes(date.getMinutes() - timeZoneOffset);
      // const formattedDate = date.toISOString().split("T")[0];

      return [
        date,
        stock.Low_Price,
        stock.Open_Price,
        stock.Closing_Price,
        stock.High_Price,
        stock.Predicted_Closing_Price,
      ];
    }),
  ];

  function signal() {
    const RealClosingPrice =
      stockPredict[stockPredict.length - 2].Closing_Price;
    const predictedClosingPrice =
      stockPredict[stockPredict.length - 1].Predicted_Closing_Price;
    if (RealClosingPrice < predictedClosingPrice) {
      return "Buy";
    } else if (RealClosingPrice > predictedClosingPrice) {
      return "Sell";
    } else {
      return "Hold";
    }
  }

  const signalClass = stockPredict.length > 1 ? signal().toLowerCase() : "";

  function setcolorScatter() {
    if (signal() === "Buy") {
      return "green";
    } else if (signal() === "Sell") {
      return "red";
    } else return "gray";
  }

  return (
    <>
      <div className="Chart-Prediction">
        <Chart
          width={"100%"}
          height={isMobile ? "400px" : "500px"}
          chartType="ComboChart"
          loader={<div> Loading Chart </div>}
          data={ComboChart}
          options={{
            title: "Stock Price Prediction",
            seriesType: "candlesticks",
            bar: { groupWidth: "70%" },
            tooltip: {
              isHtml: true,
            },
            vAxis: { title: "Price" },
            candlestick: {
              fallingColor: { strokeWidth: 0, fill: "#a52714" },
              risingColor: { strokeWidth: 0, fill: "#0f9d58" },
            },
            series: {
              1: {
                type: "scatter",
                color:
                  stockPredict.length > 1 ? setcolorScatter() : "Loading...",
                pointSize: 25,
                pointShape: {
                  type: "star",
                  sides: 5,
                },
              },
            },
          }}
        />
      </div>
      <div className="text-card">
        <p>
          On {date},{" "}
          <span style={{ fontWeight: "bolder", fontSize: 15 }}>
            {firstPart.toUpperCase()}
          </span>{" "}
          was assigned a{" "}
          <span className={signalClass}>
            {stockPredict.length > 1 ? signal() : "Loading..."}
          </span>{" "}
          signal with a target price of{" "}
          <span className={signalClass}>
            {stockPredict.length > 1
              ? stockPredict[stockPredict.length - 1].Predicted_Closing_Price
              : "Loading..."}
          </span>
          <hr />
          This signal was predicated on several factors, including news updates,
          the company's fundamental metrics, and technical analysis.
        </p>
      </div>
    </>
  );
}
