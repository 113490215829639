import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./css/Stock_Page.css";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import insightwave from "../image/insight-wave.png";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function News_card(props) {
  const { date } = useParams();
  const [stockNews, setStockNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = window.innerWidth <= 640;
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const itemsPerPage = 5;

  useEffect(() => {
    axios
      //.get(`http://localhost:3001/News/${date}/${props.myIndustryprop}`)
      .get(
        process.env.REACT_APP_API_DATABASE +
          `/News/${date}/${props.myIndustryprop}`
      )
      .then((response) => {
        setStockNews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stock news:", error);
      });
  }, [props.myIndustryprop, date]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStockNews = stockNews.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div className="all-stock">
      <div className="new">
        <h2> News </h2>{" "}
        <h5>
          {" "}
          Industry:{" "}
          {props.myIndustryprop.charAt(0).toUpperCase() +
            props.myIndustryprop.slice(1).toLowerCase()}{" "}
        </h5>{" "}
      </div>{" "}
      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {currentStockNews.map((newsItem, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{ width: "80%" }}
            >
              <AccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  {newsItem.Title.length > 30
                    ? `${newsItem.Title.slice(0, 30)}...`
                    : newsItem.Title}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  to={newsItem.Link}
                  target="_blank"
                  rel="noopener"
                  style={{ color: "black" }}
                >
                  {newsItem.Title}
                </Link>
                <Typography>
                  Date: {new Date(newsItem.Date_).toLocaleDateString()}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <div className="news-card-stock">
          {currentStockNews.map((newsItem, index) => (
            <Card
              key={index}
              sx={{ maxWidth: 345, height: "320px" }}
              className="mb-2"
            >
              <CardActionArea>
                <a
                  href={newsItem.Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      !newsItem.Links_pic ? insightwave : newsItem.Links_pic
                    }
                    alt="Links picture"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontWeight: "bolder",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {newsItem.Title}
                      <br />
                      <br />
                      <span
                        style={{
                          fontSize: "13px",
                          position: "absolute",
                          bottom: 0,
                          right: "5px",
                          margin: "5px",
                        }}
                      >
                        Date: {new Date(newsItem.Date_).toLocaleDateString()}
                      </span>
                    </Typography>
                  </CardContent>
                </a>
              </CardActionArea>
            </Card>
          ))}
        </div>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(stockNews.length / itemsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />{" "}
      </div>{" "}
    </div>
  );
}
