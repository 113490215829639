import "bootstrap/dist/css/bootstrap.min.css";
import { BsFacebook, BsInstagram, BsLine } from "react-icons/bs";

export default function Footer() {
  const backgroundColor = "rgba(0, 0, 0, 0.2)";
  return (
    <footer
      className="bg-dark text-center text-white"
      style={{
        position: "relative",
        bottom: 0,
        width: "100%",
        backgroundColor: backgroundColor,
      }}
    >
      {/* Grid container */}{" "}
      <div className="container p-4 pb-0">
        {" "}
        {/* Section: Social media */}{" "}
        <section className="mb-4">
          {" "}
          {/* Facebook */}{" "}
          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <BsFacebook />
          </a>{" "}
          {/* Instagram */}{" "}
          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <BsInstagram />
          </a>{" "}
          {/* Linkedin */}{" "}
          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <BsLine />
          </a>{" "}
        </section>{" "}
      </div>{" "}
      <div
        className="text-center p-3"
        style={{ backgroundColor: backgroundColor }}
      >
        ©2023 Copyright: Insight-Wave
      </div>{" "}
    </footer>
  );
}
