import React from "react";
import Header from "./Header";
import "./css/NotFound.css";

function NotFound() {
  return (
    <section>
      <div className="head-404">
        <Header />
      </div>
      <section className="error-page section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="error-inner">
                <h1>
                  404<span>Oop's sorry we can't find that page!</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default NotFound;
