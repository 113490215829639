import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Card } from "react-bootstrap";
import "./css/News.css";
import React, { useEffect, useState } from "react";
import News3 from "../image/News3.png";
import axios from "axios";

export default function News_card_api() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    axios
      //  .get("http://localhost:3001/News")
      .get(process.env.REACT_APP_API_DATABASE + `/News`)
      .then((response) => {
        const data = response.data;
        setNewsData(data.articles);
      })
      .catch((error) => {
        console.error("Error fetching news data:", error);
      });
  }, []);

  return (
    <div className="all">
      <h3> Insights & News </h3>{" "}
      <h5>
        {" "}
        Our service provides in -depth analysis and up - to - date information,
        ensuring you stay knowledgeable about market trends and potential
        opportunities.{" "}
      </h5>{" "}
      <div className="news-card">
        <Row xs={1} md={3} className="g-3">
          {" "}
          {newsData.map((article, index) => (
            <Col key={index}>
              <Card className="news-card-2">
                <Card.Img variant="top" src={News3} style={{ opacity: 0.8 }} />{" "}
                <Card.Body>
                  <a
                    href={article.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Card.Text className="news-title">
                      {article.url && article.url.length > 0
                        ? article.title
                        : "Loading..."}
                    </Card.Text>{" "}
                  </a>{" "}
                  <br />
                  <div className="news-date">
                    <small>
                      {" "}
                      {new Date(article.publishedAt).toLocaleDateString(
                        "en-GB"
                      )}{" "}
                    </small>{" "}
                  </div>
                </Card.Body>{" "}
              </Card>{" "}
            </Col>
          ))}{" "}
        </Row>{" "}
      </div>{" "}
    </div>
  );
}
