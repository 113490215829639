import Header from "./Header";
import React from "react";
import "./css/News.css";
import New from "../image/News-Top.png";
import Footer from "./Footer";
import NewsCardAPI from "./News-card-api";

export default function News() {
  function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  return (
    <>
      <Header />
      <div className="box">
        <img src={New} alt="News" className="news" />
      </div>{" "}
      {
        <div className="box-news">
          <h3> Business News </h3>{" "}
          <h5> Last updated: {getYesterday().toLocaleDateString("en-GB")} </h5>{" "}
        </div>
      }{" "}
      <NewsCardAPI />
      <Footer />
    </>
  );
}
