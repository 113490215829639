import "./css/Header.css";
import { RiStockFill } from "react-icons/ri";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

export default function Header() {
  return (
    <div className="head">
      <div className="logo">
        <Link to="/">
          Stock Prediction <RiStockFill />
        </Link>{" "}
      </div>{" "}
      <div className="navigation-item">
        <Link to="/HOME"> HOME </Link>{" "}
        <Link
          to="/SEARCHSTOCKS
        "
        >
          {" "}
          Stocks{" "}
        </Link>{" "}
        <Link to="/News"> News </Link>{" "}
      </div>{" "}
    </div>
  );
}
