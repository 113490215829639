import video from "../image/Video_Home.mp4";
import imageHome from "../image/IntroHome.jpg";
import Header from "./Header";
import "./css/Intro_Home.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Intro_Home() {
  const isMobile = window.innerWidth <= 640;
  return (
    <>
      <section className="showcase">
        <Header />
        {isMobile ? (
          <img src={imageHome} alt="Intro-Home" />
        ) : (
          <video src={video} autoPlay muted loop />
        )}
        <div className="text">
          <h1>Insight-Wave</h1>
          <p>
            Insight-Wave is an innovative project aimed at advancing deep
            learning techniques for predicting the stock prices of Thailand 's
            SET50 Index.It harnesses a rich dataset, including news,
            fundamental, and technical data.The primary goal is to develop a
            robust multivariate time series prediction model using unsupervised
            deep learning.
          </p>
          <Link to="/HOME">
            <BsFillArrowRightCircleFill /> Website
          </Link>
        </div>
      </section>
    </>
  );
}
